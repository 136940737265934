import React, { Component } from 'react';
import classes from './Content.module.scss'
import sakura1 from '../../assets/images/sakura1.png';
import sakura2 from '../../assets/images/sakura2.png';
import sakura3 from '../../assets/images/sakura3.png';
import sakura4 from '../../assets/images/sakura4.png';
import sakura5 from '../../assets/images/sakura5.png';
import paper from '../../assets/images/paper.png';
import yam from '../../assets/images/yam.webp';
import Badge from '../Badge/Badge';
import {fetchContent} from '../../store/actions/authAction';
import {connect} from 'react-redux';

class Content extends Component {

    componentDidMount() {
        this.props.onFetchContent(this.props.token, this.props.userId)
    }


    render () {
        let part1 = null;
        let part2 = null;
        let part3 = null;
        let part4 = null;
        let part5 = null;
        let part6 = null;
        let part7 = null;

        if (this.props.content !== null) {
            part1 = this.props.content.part1
            part2 = this.props.content.part2
            part3 = this.props.content.part3
            part4 = this.props.content.part4
            part5 = this.props.content.part5
            part6 = this.props.content.part6
            part7 = this.props.content.part7
        }
        return (
            <div className={classes.Container}>
                <div className={classes.ContentContainer}>
                    <h3>{part1}</h3>
                    <p>{part2}</p>
                    <p>{part3}</p>
                    <p>{part4}</p>
                    <p>{part5}</p>
                    <p>{part6}</p>
                    <div className={classes.ClosingContainer}>
                        <h3>{part7}</h3>
                        <img className={classes.YamSticker} src={yam} alt='#'/>
                    </div>
                    
                </div>
                
                <div className={classes.PaperContainer}>
                    <div className={classes.BadgeContainer}><Badge/></div>                    
                    <img className={classes.Paper} src={paper} alt='#'/>
                </div>        
                <div className={classes.Set1}>
                    <div><img className={classes.Sakura1} src={sakura1} alt='#'/></div>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                    <div><img className={classes.Sakura3} src={sakura3} alt='#'/></div>
                    <div><img className={classes.Sakura4} src={sakura4} alt='#'/></div>
                    <div><img className={classes.Sakura5} src={sakura5} alt='#'/></div>
                    <div><img className={classes.Sakura1} src={sakura1} alt='#'/></div>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                    <div><img className={classes.Sakura3} src={sakura3} alt='#'/></div>
                    <div><img className={classes.Sakura4} src={sakura4} alt='#'/></div>
                    <div><img className={classes.Sakura5} src={sakura5} alt='#'/></div>
                </div>
                <div className={classes.Set2}>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                    <div><img className={classes.Sakura3} src={sakura3} alt='#'/></div>
                    <div><img className={classes.Sakura1} src={sakura1} alt='#'/></div>
                    <div><img className={classes.Sakura3} src={sakura3} alt='#'/></div>
                    <div><img className={classes.Sakura4} src={sakura4} alt='#'/></div>
                    <div><img className={classes.Sakura3} src={sakura3} alt='#'/></div>
                </div>
                <div className={classes.Set3}>
                    <div><img className={classes.Sakura1} src={sakura1} alt='#'/></div>
                    <div><img className={classes.Sakura1} src={sakura1} alt='#'/></div>
                    <div><img className={classes.Sakura1} src={sakura1} alt='#'/></div>
                    <div><img className={classes.Sakura1} src={sakura1} alt='#'/></div>
                    <div><img className={classes.Sakura1} src={sakura1} alt='#'/></div>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                    <div><img className={classes.Sakura2} src={sakura2} alt='#'/></div>
                </div>                
            </div>
        )
    }


}

const mapStateToProps = state => {
    return {
        isAuth: state.authReducer.token !== null,
        isLoading: state.authReducer.isLoading,
        token: state.authReducer.token,
        userId: state.authReducer.userId,
        content: state.authReducer.content
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchContent: (token, userId) => dispatch(fetchContent(token, userId))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Content);