import axios from 'axios';


const signInURL = 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=' + process.env.REACT_APP_FIREBASE_KEY;
const fireBaseApiURL = 'https://ajpx-32ed7-default-rtdb.firebaseio.com/';


export const authSuccess = (token, userId) => {
    return {
        type: 'AUTH_SUCCESS',
        userId: userId,
        token: token
    }
}

export const updateLoading = (bLoading) => {
    return {
        type: 'UPDATE_LOADING',
        isLoading: bLoading
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
    localStorage.removeItem('userId');
    return {
        type: 'LOGOUT'
    }
}


export const auth = (password) => {
    return dispatch => {
        dispatch(updateLoading(true))
        let sEmail = 'jaspher@jaspher.com'
        if (password === 'password102938') {
            sEmail = 'test@test.com'
        }
        const oAuthData = {
            email : sEmail,
            password : password,
            returnSecureToken: true
        }
        axios.post(signInURL, oAuthData)
        .then(res => {
            const expirationDate = new Date(new Date().getTime() + (res.data.expiresIn * 1000))
            localStorage.setItem('token', res.data.idToken);
            localStorage.setItem('expirationDate', expirationDate);
            localStorage.setItem('userId', res.data.localId);
            dispatch(authSuccess(res.data.idToken, res.data.localId))
            dispatch(updateLoading(false))                       
        })
        .catch(err => {
            dispatch(updateLoading(false))
            dispatch(authFail(err.response.data.error.message))            
        })        
    }
}

export const updateContent = (content) => {
    return {
        type: 'UPDATE_CONTENT',
        content: content
    }
}

export const fetchContent = (token, userId) => {
    return dispatch => {
        const queryParams = '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
        axios.get(fireBaseApiURL + '/content.json' + queryParams)
            .then(res => {
                const sKey = Object.keys(res.data)[0]
                dispatch(updateContent(res.data[sKey]))
            })
            .catch(err => {
                console.log(err)
                alert('Something went wrong...')
                dispatch(logout())
                window.location.reload()
            })
    }    
}

export const authCheckState = () => {
    return dispatch => {
       const token = localStorage.getItem('token')
       if (token === null) {
            dispatch(logout())
       } else {
            dispatch(authCheckExpirationDate())
       }
    }
}

export const authCheckExpirationDate = () => {
    return dispatch => {
        const expirationDate = new Date(localStorage.getItem('expirationDate'));
        const token = localStorage.getItem('token')
        const userId = localStorage.getItem('userId');
        if (expirationDate <= new Date()) {
            dispatch(logout());
        } else {        
            dispatch(authSuccess(token, userId));
            dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000))
        }
    }
    
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    }
}

export const authFail = (err) => {
    return dispatch => {
        alert(err);
    }
}