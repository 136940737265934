import classes from './Badge.module.scss'
import sakura2 from '../../assets/images/sakura2.png';
import ally1 from '../../assets/images/ally/ally1.jpg';
import ally2 from '../../assets/images/ally/ally2.jpg';
import ally3 from '../../assets/images/ally/ally3.jpg';
import ally4 from '../../assets/images/ally/ally4.jpg';
import ally5 from '../../assets/images/ally/ally5.jpg';
import ally6 from '../../assets/images/ally/ally6.jpg';
import React, { Component} from 'react';



class Badge extends Component {

    state = {
        imageCounter : 0,
        images : [
            sakura2, 
            ally1,
            ally2,
            ally3,
            ally4,
            ally5,
            ally6
        ],
        fadeIn: false
           
    }

    changeImage = () => {
        const counter = this.state.imageCounter;
        const imagesNumber = this.state.images.length - 1          
        if ( imagesNumber === counter) {
            this.setState({imageCounter: 0})
        } else {
            let incrementCounter = this.state.imageCounter + 1;
            this.setState({imageCounter: incrementCounter})
        }
        this.setState({fadeIn: true})
    }


    render () {
        let classFade = '';         
        if (this.state.fadeIn === true) {
            classFade = classes.FadeInImage
            setTimeout(() => {
                this.setState({fadeIn: false})
            }, 650); 
        }
        
        return (
            <div className={classes.Container}>
                <img className={classFade} onClick={this.changeImage} src={this.state.images[this.state.imageCounter]} alt='#'/>
                <span></span>
                <span></span>
                <span></span>
            </div>
        )
    }
}


export default Badge;